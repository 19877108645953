import type { Modal } from './types'

export const create = (
  modals: Map<string, Modal>,
  id: string = uid(),
): Modal => {
  const visible = ref<boolean>(false)

  const open = (): void => {
    if (!visible.value) {
      visible.value = true
    }
  }

  const close = (): void => {
    if (visible.value) {
      visible.value = false
    }
  }

  const toggle = (): void => (visible.value ? close() : open())

  const onDocumentClick = ({ target }: Event): void => {
    const isElement = target instanceof Element

    if (!isElement) {
      close()
      return
    }

    const modal = target.closest(`[data-modal="${id}"]`)
    const trigger = target.closest(`[data-modal-target="${id}"]`)

    if (!modal && !trigger) close()
  }

  watch(visible, (visible) => {
    if (visible) {
      document.addEventListener('click', onDocumentClick, true)
    } else {
      document.removeEventListener('click', onDocumentClick, true)
    }
  })

  const modal = reactive({
    id,
    visible,
    open,
    close,
    toggle,
  })

  onUnmounted(() => {
    modals.delete(id)
    document.removeEventListener('click', onDocumentClick, true)
  })

  onBeforeMount(() => {
    modals.set(id, modal)
  })

  return modal
}
