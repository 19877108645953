export default defineNuxtRouteMiddleware(async (to) => {
  if (to.meta.public) return

  const { $i18n } = useNuxtApp()

  const localePath = useLocalePath()

  const identityService = useIdentityService()

  const loginUrl =
    to.path !== '/'
      ? `/login?returnUrl=${to.fullPath}`
      : localePath('/login', $i18n.locale.value)

  if (!identityService.data.accessToken && !identityService.data.refreshToken)
    return resolvePage(loginUrl)

  try {
    if (!identityService.data.refreshToken) {
      throw createError({ statusCode: 401 })
    }

    if (identityService.isExpired()) {
      const data = await identityService.requestNewTokens()
      await identityService.syncCookie(data)
    }

    if (!identityService.data.userDetails) {
      await identityService.fetchUserDetails()
    }
  } catch (error) {
    const isServer = isServerError(error)

    if (isServer && (error.status === 401 || error.statusCode === 401)) {
      identityService.clear()

      return resolvePage(loginUrl)
    } else {
      return showError({
        statusCode: isServer ? error.status || error.statusCode : 500,
      })
    }
  }
})

const resolvePage = (loginUrl: string) =>
  process.server ? navigateTo(loginUrl) : showError({ statusCode: 401 })

const isServerError = (
  error: unknown,
): error is { status: number; statusCode: number } =>
  typeof error === 'object' &&
  error !== null &&
  ('status' in error || 'statusCode' in error)
