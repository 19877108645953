import type { DirectiveBinding } from 'vue'

export default defineNuxtPlugin(({ vueApp }) => {
  const count = (el: HTMLElement, binding: DirectiveBinding<number>) => {
    el.classList.toggle('counter', binding.value > 0)

    if (binding.value) el.dataset.counter = `${binding.value}`
    else el.removeAttribute('data-counter')
  }

  vueApp.directive('counter', {
    mounted: count,
    updated: count,
  })
})
