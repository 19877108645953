<template>
  <NuxtLayout>
    <div :class="$style.main">
      <div :class="$style.container">
        <h2 :class="$style.title">{{ title }}</h2>
        <img src="/images/error.png" :class="$style.img" />

        <UiBaseButton
          v-if="error.statusCode === 401"
          :class="$style.button"
          @click="onClick($link.login())"
        >
          {{ $t('login.signIn') }}
        </UiBaseButton>
      </div>
    </div>
  </NuxtLayout>
</template>

<script lang="ts" setup>
defineProps({
  error: null
})

const { t } = useI18n()

const title = computed<string>(() => {
  const data: Record<number, string> = {
    500: t('error.500'),
    404: t('error.404'),
    403: t('error.403'),
    401: t('error.401'),
    400: t('error.400'),
  }

  return data[__props.error.statusCode] || data[500]
})

const onClick = async (path: string): Promise<void> => {
  await clearError()
  location.href = path
}
</script>

<style lang="scss" module>
.main {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 20px;
}

.container {
  display: grid;
  gap: 30px;
  width: 100%;
  max-width: 920px;
  margin: 0 auto;
  padding: 96px 36px;
  text-align: center;
  background: #fff;

  @include media($to: xs) {
    padding: 56px 24px;
  }
}

.title {
  font-size: 40px;
  line-height: 1;

  @include media($to: sm) {
    font-size: 28px;
  }

  @include media($to: xs) {
    font-size: 24px;
  }
}

.button {
  margin-inline: auto;
  min-width: 160px;
}

.img {
  display: block;
  margin-inline: auto;
  max-width: 100%;
}
</style>
