<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <TheToast />
  </div>
</template>

<script lang="ts" setup>
useHead({
  titleTemplate(title) {
    return title ? `Iris - ${title}` : 'Iris'
  },
})

const { hook } = useNuxtApp()

hook('vue:error', (e) => {
  console.dir(e)
  if (
    e instanceof Error &&
    e.message.includes('Failed to fetch dynamically imported module') &&
    e.message.includes('/pages')
  ) {
    showError({ statusCode: 500 })
  }
})

useIdleLogout()
</script>
