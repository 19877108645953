export type AccessNamespaces =
  | 'departments'
  | 'users'
  | 'locations'
  | 'refugees'
  | 'locationsAll'
  | 'scanners'
  | 'global'
  | 'transfers'
  | 'medical'

export interface AccessNamespace {
  read: boolean
  write: boolean
}

export type Access = {
  [K in AccessNamespaces]: AccessNamespace
}

export default defineNuxtPlugin(() => {
  const identityService = useIdentityService()

  const access = computed<Access>(() => {
    const roles = identityService.data.userDetails?.realm_access.roles || []

    return {
      departments: {
        read: roles.includes('departments.read'),
        write: roles.includes('departments.write'),
      },
      users: {
        read: roles.includes('users.read'),
        write: roles.includes('users.write'),
      },
      locations: {
        read: roles.includes('locations.read'),
        write: roles.includes('locations.write'),
      },
      refugees: {
        read: roles.includes('refugees.read'),
        write: roles.includes('refugees.write'),
      },
      medical: {
        read: roles.includes('medical.read'),
        write: roles.includes('medical.write'),
      },
      locationsAll: {
        read: roles.includes('locations.all.read'),
        write: roles.includes('locations.all.write'),
      },
      scanners: {
        read: roles.includes('scanners.read'),
        write: roles.includes('scanners.write'),
      },
      transfers: {
        read: roles.includes('transfers.read'),
        write: roles.includes('transfers.write'),
      },
      global: {
        read: [
          'departments.read',
          'users.read',
          'locations.read',
          'locations.all.read',
        ].some((scope) => roles.includes(scope)),
        write: [
          'departments.write',
          'users.write',
          'locations.write',
          'locations.all.write',
        ].some((scope) => roles.includes(scope)),
      },
    }
  })

  return {
    provide: {
      access,
    },
  }
})
