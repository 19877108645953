import type { CookieRef } from 'nuxt/app'
import type { IdentityServiceCookie } from './types'

export const useCookieStore = (): IdentityServiceCookie => {
  const runtimeConfig = useRuntimeConfig()

  const accessToken = useCookie(runtimeConfig.public.accessTokenKey)
  const refreshToken = useCookie(runtimeConfig.public.refreshTokenKey)

  const expiredAt = (value: number): Date => {
    const date = new Date()

    date.setSeconds(date.getSeconds() + value)

    return date
  }

  return {
    data: reactive<{
      accessToken: CookieRef<string | null | undefined>
      refreshToken: CookieRef<string | null | undefined>
    }>({
      accessToken,
      refreshToken,
    }),
    set(props) {
      const accessToken = useCookie(runtimeConfig.public.accessTokenKey, {
        expires: expiredAt(props.expires_in),
      })

      const refreshToken = useCookie(runtimeConfig.public.refreshTokenKey, {
        expires: expiredAt(props.refresh_expires_in),
      })

      accessToken.value = props.access_token
      refreshToken.value = props.refresh_token
    },
    clear() {
      accessToken.value = null
      refreshToken.value = null
    },
  }
}
