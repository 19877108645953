import type { IdentityServiceStore, IdentityServiceUserDetails } from './types'

export const useStore = (): IdentityServiceStore => {
  const accessToken = useState<string>('identityService:accessToken', () => '')
  const refreshToken = useState<string>(
    'identityService:refreshToken',
    () => '',
  )

  const userDetails = useState<IdentityServiceUserDetails | null>(
    'identityService:userDetails',
    () => null,
  )

  return reactive<{
    [K in keyof IdentityServiceStore]: Ref<IdentityServiceStore[K]>
  }>({
    accessToken,
    refreshToken,
    userDetails,
  })
}
