<template>
  <component
    :is="tag"
    :type="computedType"
    :to="to"
    :class="[
      $style.button,
      $style[`button_ui-${ui}`],
      $style[`button_size-${size}`],
      radius && $style[`button_radius-${radius}`],
    ]"
    :disabled="disabled || loading"
  >
    <UiBaseIcon v-if="loading" name="loader-2" :class="$style.button__loader" />
    <slot />
  </component>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'

const props = defineProps({
  type: { default: 'button' },
  ui: { default: 'primary' },
  size: { default: 'md' },
  radius: { default: undefined },
  loading: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
  to: { default: undefined }
})

const tag = computed(() => (props.to ? resolveComponent('NuxtLink') : 'button'))

const computedType = computed(() =>
  tag.value === 'button' ? props.type : undefined,
)
</script>

<style lang="scss" module>
.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--button-height);
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  border-radius: calc(var(--button-height) / 2);

  &__loader {
    position: absolute;
    top: 50%;
    left: 12px;
    width: 16px;
    margin-top: -8px;
    animation: rotate 1s infinite linear;
  }

  svg:not(.button__loader) {
    width: 24px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }

  &_ui-primary {
    color: #fff;
    background: #6045e2;
    box-shadow: 0 5px 10px rgba(4, 0, 252, 0.2);
    cursor: pointer;
    transition:
      opacity 0.3s ease,
      background-color 0.3s ease;

    @include interact {
      background: var(--accent-color);
    }
  }

  &_ui-primary-stroke {
    color: var(--accent-color);
    border: 2px solid var(--accent-color);
    cursor: pointer;
    transition:
      color 0.3s ease,
      opacity 0.3s ease,
      background-color 0.3s ease;

    @include interact {
      color: #fff;
      background: var(--accent-color);
    }
  }

  &_ui-danger {
    color: var(--danger-color);
    background: none;
    transition:
      opacity 0.3s ease,
      background-color 0.3s ease;

    @include interact {
      background: var(--danger-light-color);
    }
  }

  &_ui-success {
    color: #fff;
    background: var(--success-color);
    border: 1px solid var(--success-color);
    cursor: pointer;
    transition:
      color 0.3s ease,
      opacity 0.3s ease,
      background-color 0.3s ease;

    @include interact {
      color: var(--success-color);
      background: #fff;
    }
  }

  &_size-xs {
    --button-height: 20px;
    padding-inline: 8px;
  }

  &_size-sm {
    --button-height: 32px;
    padding-inline: 16px;
  }

  &_size-md {
    --button-height: 40px;
    padding: 0 36px;
  }

  &_size-lg {
    --button-height: 48px;
    padding: 0 48px;
  }

  &_radius-none {
    border-radius: 0;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(1turn);
  }
}
</style>
