<template>
  <svg viewBox="0 0 24 24" :class="$style.icon">
    <use :xlink:href="url" />
  </svg>
</template>

<script lang="ts" setup>
const props = defineProps({
  name: null
})

const appConfig = useAppConfig()

const url = computed(
  () =>
    `/images/sprite.svg?buildVersion=${appConfig.buildVersion}#${props.name}`,
)
</script>

<style lang="scss" module>
.icon {
  display: inline-block;
  aspect-ratio: 1/1;
  vertical-align: top;
  fill: none;
  stroke: currentColor;
  stroke-width: 2px;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
