import { create } from './create'
import type { Modal } from './types'

export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive('modalTarget', {
    mounted(el, binding) {
      el.dataset.modalTarget = binding.value
      el.dataset.modalAction = binding.modifiers.open
        ? 'open'
        : binding.modifiers.close
          ? 'close'
          : 'toggle'

      el.removeEventListener('click', onTriggerClick)
      el.addEventListener('click', onTriggerClick)
    },
    beforeUnmount(el) {
      el.removeAttribute('data-modal-target')
      el.removeAttribute('data-modal-action')
      el.removeEventListener('click', onTriggerClick)
    },
  })

  vueApp.directive('modal', {
    mounted(el, binding) {
      el.dataset.modal = binding.value
    },
    beforeUnmount(el) {
      el.removeAttribute('data-modal')
    },
  })

  const onTriggerClick = (e: Event) => {
    if (!(e.currentTarget instanceof Element)) return

    const triggerTarget = e.currentTarget.getAttribute('data-modal-target')
    const triggerAction = e.currentTarget.getAttribute('data-modal-action')

    if (typeof triggerTarget !== 'string') return

    e.preventDefault()

    const modal = createdModals.get(triggerTarget)

    if (modal) {
      if (triggerAction === 'open') modal.open()
      else if (triggerAction === 'close') modal.close()
      else modal.toggle()
    }
  }

  const createdModals = new Map<string, Modal>()

  return {
    provide: {
      appModal: {
        id: {
          mobileMenu: 'mobileMenu',
          deleteDepartment: 'deleteDepartment',
          manageDepartment: 'manageDepartment',
          employeeActivation: 'employeeActivation',

          createLocation: 'createLocation',
          manageLocation: 'manageLocation',
          deleteLocation: 'deleteLocation',

          createRoom: 'createRoom',
          manageRoom: 'manageRoom',
          deleteRoom: 'deleteRoom',

          roomsFilters: 'roomsFilters',
        },
        close: (id: string): void => {
          createdModals.get(id)?.close()
        },
        open: (id: string): void => {
          createdModals.get(id)?.open()
        },
        create: (id?: string) => create(createdModals, id),
      },
    },
  }
})
