export default defineNuxtRouteMiddleware(async (to) => {
  if (!to.meta.public) return

  const { $i18n } = useNuxtApp()

  const localePath = useLocalePath()

  const identityService = useIdentityService()

  const redirectUrl =
    typeof to.query.returnUrl === 'string'
      ? to.query.returnUrl
      : localePath('/', $i18n.locale)

  if (!identityService.data.refreshToken) {
    identityService.clear()

    return
  }

  try {
    if (identityService.data.accessToken) {
      if (!identityService.data.userDetails)
        await identityService.fetchUserDetails()
    } else if (identityService.data.refreshToken) {
      if (identityService.isExpired()) {
        const data = await identityService.requestNewTokens()
        await identityService.syncCookie(data)
      }

      if (!identityService.data.userDetails)
        await identityService.fetchUserDetails()
    }

    return navigateTo(redirectUrl)
  } catch (error) {
    identityService.clear()
  }
})
