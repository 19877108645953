type ContextPage = (locationId?: string | null) => string

type TransferPage = (locationId: string, transferId: string) => string

type TransferCheckIn = (
  locationId: string,
  transferId: string,
  refugeeId: string,
) => string

export interface Links {
  locations: Record<'list' | 'create', () => string> &
    Record<'details', ContextPage>

  employee: Record<'list' | 'registration', () => string> &
    Record<'details', (employeeId: string) => string>

  departments: Record<'list', () => string>

  scanners: Record<'list' | 'registration', () => string> &
    Record<'details', (scannerId: string) => string>

  refugees(): string
  rooms(): string
  login(): string
  index(): string
  forgotPassword(): string
  support(): string
  security(): string

  context: {
    dashboard: ContextPage

    transfers: {
      create: ContextPage
      in: {
        list: ContextPage
        completed: ContextPage
        cancelled: ContextPage
        details: TransferPage
        checkIn: TransferCheckIn
        checkInRoom: TransferCheckIn
      }
      out: {
        list: ContextPage
        draft: ContextPage
        completed: ContextPage
        cancelled: ContextPage
        details: TransferPage
        selection: TransferPage
      }
    }

    refugees: Record<'list' | 'registration', ContextPage> &
      Record<
        'details' | 'history' | 'family' | 'room' | 'messages',
        (
          props: Partial<Record<'locationId' | 'refugeeId', string | null>>,
        ) => string
      >
    rooms: Record<'list' | 'create', ContextPage> &
      Record<
        'details' | 'refugee',
        (
          props: Partial<Record<'locationId' | 'roomId', string | null>>,
        ) => string
      >

    medical: {
      list: ContextPage
      profile: (locationId: string, refugeeId: string) => string
      room: (locationId: string, refugeeId: string) => string
      history: (locationId: string, refugeeId: string) => string
    }
  }
}

export default defineNuxtPlugin(() => {
  const localePath = useLocalePath()

  const link: Links = {
    context: {
      dashboard: (locationId) => localePath(`/context/${locationId}/`),

      transfers: {
        in: {
          list: (locationId) =>
            localePath(`/context/${locationId}/transfers/in/`),
          completed: (locationId) =>
            localePath(`/context/${locationId}/transfers/in/completed/`),
          cancelled: (locationId) =>
            localePath(`/context/${locationId}/transfers/in/cancelled/`),
          details: (locationId, transferId) =>
            localePath(`/context/${locationId}/transfers/in/${transferId}`),
          checkIn: (locationId, transferId, refugeeId) =>
            localePath(
              `/context/${locationId}/transfers/in/${transferId}/${refugeeId}`,
            ),
          checkInRoom: (locationId, transferId, refugeeId) =>
            localePath(
              `/context/${locationId}/transfers/in/${transferId}/${refugeeId}/room`,
            ),
        },
        out: {
          list: (locationId) =>
            localePath(`/context/${locationId}/transfers/out/`),
          draft: (locationId) =>
            localePath(`/context/${locationId}/transfers/out/draft/`),
          completed: (locationId) =>
            localePath(`/context/${locationId}/transfers/out/completed/`),
          cancelled: (locationId) =>
            localePath(`/context/${locationId}/transfers/out/cancelled/`),
          details: (locationId, transferId) =>
            localePath(`/context/${locationId}/transfers/out/${transferId}`),
          selection: (locationId, transferId) =>
            localePath(
              `/context/${locationId}/transfers/out/${transferId}/refugees`,
            ),
        },
        create: (locationId) =>
          localePath(`/context/${locationId}/transfers/create/`),
      },

      refugees: {
        list: (locationId) => localePath(`/context/${locationId}/refugees/`),
        registration: (locationId) =>
          localePath(`/context/${locationId}/refugees/registration`),
        details: (props) =>
          localePath(
            `/context/${props.locationId}/refugees/${props.refugeeId}/`,
          ),
        history: (props) =>
          localePath(
            `/context/${props.locationId}/refugees/${props.refugeeId}/history/`,
          ),
        family: (props) =>
          localePath(
            `/context/${props.locationId}/refugees/${props.refugeeId}/family/`,
          ),
        room: (props) =>
          localePath(
            `/context/${props.locationId}/refugees/${props.refugeeId}/room/`,
          ),
        messages: (props) =>
          localePath(
            `/context/${props.locationId}/refugees/${props.refugeeId}/messages/`,
          ),
      },
      rooms: {
        list: (locationId) => localePath(`/context/${locationId}/rooms/`),
        create: (location) => localePath(`/context/${location}/rooms/create`),
        details: (props) =>
          localePath(`/context/${props.locationId}/rooms/${props.roomId}/`),
        refugee: (props) =>
          localePath(
            `/context/${props.locationId}/rooms/${props.roomId}/refugee/`,
          ),
      },

      medical: {
        list: (locationId) => localePath(`/context/${locationId}/medical/`),
        profile: (locationId, refugeeId) =>
          localePath(`/context/${locationId}/medical/${refugeeId}`),
        room: (locationId, refugeeId) =>
          localePath(`/context/${locationId}/medical/${refugeeId}/room/`),
        history: (locationId, refugeeId) =>
          localePath(`/context/${locationId}/medical/${refugeeId}/history/`),
      },
    },

    locations: {
      list: () => localePath('/locations'),
      details: (location) => localePath(`/locations/${location}/`),
      create: () => localePath('/locations/create'),
    },

    employee: {
      list: () => localePath('/employee'),
      registration: () => localePath('/employee/registration'),
      details: (id) => localePath(`/employee/${id}`),
    },

    departments: { list: () => localePath('/departments') },

    scanners: {
      list: () => localePath('/scanners'),
      registration: () => localePath('/scanners/registration'),
      details: (id) => localePath(`/scanners/${id}`),
    },

    refugees: () => localePath('/refugees'),
    rooms: () => localePath('/rooms'),
    login: () => localePath('/login'),
    index: () => localePath('/'),
    forgotPassword: () => localePath('/forgot-password'),
    support: () => localePath('/support'),
    security: () => localePath('/security'),
  }

  return {
    provide: {
      link,
    },
  }
})
