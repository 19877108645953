import { useIdle } from '@vueuse/core'

export const useIdleLogout = () => {
  const minutes = 15

  const timestamp = minutes * 60 * 1000

  const timestampWithThreshold = (minutes - 0.5) * 60 * 1000

  const identityService = useIdentityService()

  const { idle, lastActive } = useIdle(timestamp)

  const storageKey = 'iris:lastActive'

  watch(idle, () => {
    if (!idle.value || !identityService.data.userDetails || import.meta.env.DEV)
      return

    const storageLastActive = localStorage.getItem(storageKey)

    const storageLastActiveTimestamp =
      typeof storageLastActive === 'string' ? +storageLastActive : 0

    const shouldLogout =
      Date.now() - storageLastActiveTimestamp >= timestampWithThreshold

    if (shouldLogout) identityService.logout()
  })

  watch(lastActive, (lastActive) => {
    localStorage.setItem(storageKey, `${lastActive}`)
  })
}
