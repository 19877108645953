import { default as indexzPIT0yPxUkMeta } from "/app/src/pages/context/[contextId]/index.vue?macro=true";
import { default as historyy2wjuG44KEMeta } from "/app/src/pages/context/[contextId]/medical/[refugeeId]/history.vue?macro=true";
import { default as indexOqxwCc91bmMeta } from "/app/src/pages/context/[contextId]/medical/[refugeeId]/index.vue?macro=true";
import { default as roomX3DpPlrR6UMeta } from "/app/src/pages/context/[contextId]/medical/[refugeeId]/room.vue?macro=true";
import { default as indexCgOeJ37uImMeta } from "/app/src/pages/context/[contextId]/medical/index.vue?macro=true";
import { default as medicalO01VaFmbweMeta } from "/app/src/pages/context/[contextId]/medical.vue?macro=true";
import { default as family59MvkaU3rAMeta } from "/app/src/pages/context/[contextId]/refugees/[refugeeId]/family.vue?macro=true";
import { default as history8XGfGJZsDDMeta } from "/app/src/pages/context/[contextId]/refugees/[refugeeId]/history.vue?macro=true";
import { default as indexM3Lwq8moLJMeta } from "/app/src/pages/context/[contextId]/refugees/[refugeeId]/index.vue?macro=true";
import { default as messagesuNCDkhiHLIMeta } from "/app/src/pages/context/[contextId]/refugees/[refugeeId]/messages.vue?macro=true";
import { default as roomS0DFCh2EaYMeta } from "/app/src/pages/context/[contextId]/refugees/[refugeeId]/room.vue?macro=true";
import { default as index1RJof10h1bMeta } from "/app/src/pages/context/[contextId]/refugees/index.vue?macro=true";
import { default as registrationUjiwBlmesRMeta } from "/app/src/pages/context/[contextId]/refugees/registration.vue?macro=true";
import { default as refugeespqdM4e9bwEMeta } from "/app/src/pages/context/[contextId]/refugees.vue?macro=true";
import { default as indexnD2XFgPW3eMeta } from "/app/src/pages/context/[contextId]/rooms/[roomId]/index.vue?macro=true";
import { default as refugeeuI44YXvmf8Meta } from "/app/src/pages/context/[contextId]/rooms/[roomId]/refugee.vue?macro=true";
import { default as createEZtd3GpfOJMeta } from "/app/src/pages/context/[contextId]/rooms/create.vue?macro=true";
import { default as indexfgydtwXQLJMeta } from "/app/src/pages/context/[contextId]/rooms/index.vue?macro=true";
import { default as roomszQDu5nRonmMeta } from "/app/src/pages/context/[contextId]/rooms.vue?macro=true";
import { default as createPgNEgWZ7XfMeta } from "/app/src/pages/context/[contextId]/transfers/create.vue?macro=true";
import { default as indexoGQSu0tqojMeta } from "/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/index.vue?macro=true";
import { default as roomteb3Kqv0IXMeta } from "/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/room.vue?macro=true";
import { default as index6ZlrG1xclsMeta } from "/app/src/pages/context/[contextId]/transfers/in/[transferId]/index.vue?macro=true";
import { default as cancelledIfuxV0VX7iMeta } from "/app/src/pages/context/[contextId]/transfers/in/cancelled.vue?macro=true";
import { default as completedbliJH0RYECMeta } from "/app/src/pages/context/[contextId]/transfers/in/completed.vue?macro=true";
import { default as indexTAwsmxnwzyMeta } from "/app/src/pages/context/[contextId]/transfers/in/index.vue?macro=true";
import { default as in6aHw23rnH9Meta } from "/app/src/pages/context/[contextId]/transfers/in.vue?macro=true";
import { default as indexlNmgSRCbvkMeta } from "/app/src/pages/context/[contextId]/transfers/out/[transferId]/index.vue?macro=true";
import { default as refugees9d1nCJlsL9Meta } from "/app/src/pages/context/[contextId]/transfers/out/[transferId]/refugees.vue?macro=true";
import { default as cancelledShJxGYiw28Meta } from "/app/src/pages/context/[contextId]/transfers/out/cancelled.vue?macro=true";
import { default as completedfXUEHbmADKMeta } from "/app/src/pages/context/[contextId]/transfers/out/completed.vue?macro=true";
import { default as draftFZJFBTkeaqMeta } from "/app/src/pages/context/[contextId]/transfers/out/draft.vue?macro=true";
import { default as indexlcs957ioY1Meta } from "/app/src/pages/context/[contextId]/transfers/out/index.vue?macro=true";
import { default as outkQjkX0TgKVMeta } from "/app/src/pages/context/[contextId]/transfers/out.vue?macro=true";
import { default as transfersnIRY7rdAtXMeta } from "/app/src/pages/context/[contextId]/transfers.vue?macro=true";
import { default as _91contextId_93Cp1xmLetI7Meta } from "/app/src/pages/context/[contextId].vue?macro=true";
import { default as departmentsUVfcZw746CMeta } from "/app/src/pages/departments.vue?macro=true";
import { default as _91id_93q3k6oyjHI5Meta } from "/app/src/pages/employee/[id].vue?macro=true";
import { default as indexI4HhVoZ9sLMeta } from "/app/src/pages/employee/index.vue?macro=true";
import { default as registration8aeFU8wNyLMeta } from "/app/src/pages/employee/registration.vue?macro=true";
import { default as employeelAR8rsOsJXMeta } from "/app/src/pages/employee.vue?macro=true";
import { default as forgot_45passwordvHMplVNcGwMeta } from "/app/src/pages/forgot-password.vue?macro=true";
import { default as indexdPwi6hPdMfMeta } from "/app/src/pages/index.vue?macro=true";
import { default as _91id_93CNRQ982hTLMeta } from "/app/src/pages/invite/[id].vue?macro=true";
import { default as _91locationId_93jSvSR7CNxhMeta } from "/app/src/pages/locations/[locationId].vue?macro=true";
import { default as createSe03WvH6AoMeta } from "/app/src/pages/locations/create.vue?macro=true";
import { default as index3GLwBk4lUUMeta } from "/app/src/pages/locations/index.vue?macro=true";
import { default as locationstjOMXREOfBMeta } from "/app/src/pages/locations.vue?macro=true";
import { default as login3hUYZUGxmUMeta } from "/app/src/pages/login.vue?macro=true";
import { default as refugeesmy0KMGzBodMeta } from "/app/src/pages/refugees.vue?macro=true";
import { default as _91id_93aEoJNaKwmLMeta } from "/app/src/pages/reset-password/[id].vue?macro=true";
import { default as roomsoMnB2LmSmLMeta } from "/app/src/pages/rooms.vue?macro=true";
import { default as _91scannerId_93V9C3myOlntMeta } from "/app/src/pages/scanners/[scannerId].vue?macro=true";
import { default as indexh29AHfaGptMeta } from "/app/src/pages/scanners/index.vue?macro=true";
import { default as registrationeJV6toxVOhMeta } from "/app/src/pages/scanners/registration.vue?macro=true";
import { default as scannersIFcHra0jmUMeta } from "/app/src/pages/scanners.vue?macro=true";
import { default as security8BvUWjCqjBMeta } from "/app/src/pages/security.vue?macro=true";
import { default as supportyZydZhmckpMeta } from "/app/src/pages/support.vue?macro=true";
export default [
  {
    name: _91contextId_93Cp1xmLetI7Meta?.name,
    path: "/context/:contextId()",
    component: () => import("/app/src/pages/context/[contextId].vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId___de",
    path: "",
    component: () => import("/app/src/pages/context/[contextId]/index.vue").then(m => m.default || m)
  },
  {
    name: medicalO01VaFmbweMeta?.name,
    path: "medical",
    component: () => import("/app/src/pages/context/[contextId]/medical.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-medical-refugeeId-history___de",
    path: ":refugeeId()/history",
    meta: historyy2wjuG44KEMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId___de",
    path: ":refugeeId()",
    meta: indexOqxwCc91bmMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId-room___de",
    path: ":refugeeId()/room",
    meta: roomX3DpPlrR6UMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical___de",
    path: "",
    meta: indexCgOeJ37uImMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: refugeespqdM4e9bwEMeta?.name,
    path: "refugees",
    component: () => import("/app/src/pages/context/[contextId]/refugees.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-refugees-refugeeId-family___de",
    path: ":refugeeId()/family",
    meta: family59MvkaU3rAMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/family.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-history___de",
    path: ":refugeeId()/history",
    meta: history8XGfGJZsDDMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId___de",
    path: ":refugeeId()",
    meta: indexM3Lwq8moLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-messages___de",
    path: ":refugeeId()/messages",
    meta: messagesuNCDkhiHLIMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/messages.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-room___de",
    path: ":refugeeId()/room",
    meta: roomS0DFCh2EaYMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees___de",
    path: "",
    meta: index1RJof10h1bMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-registration___de",
    path: "registration",
    meta: registrationUjiwBlmesRMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: roomszQDu5nRonmMeta?.name,
    path: "rooms",
    component: () => import("/app/src/pages/context/[contextId]/rooms.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-rooms-roomId___de",
    path: ":roomId()",
    meta: indexnD2XFgPW3eMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-roomId-refugee___de",
    path: ":roomId()/refugee",
    meta: refugeeuI44YXvmf8Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/refugee.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-create___de",
    path: "create",
    meta: createEZtd3GpfOJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/create.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms___de",
    path: "",
    meta: indexfgydtwXQLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "context-contextId-transfers___de",
    path: "transfers",
    component: () => import("/app/src/pages/context/[contextId]/transfers.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-create___de",
    path: "create",
    meta: createPgNEgWZ7XfMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: in6aHw23rnH9Meta?.name,
    path: "in",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-in-transferId-refugeeId___de",
    path: ":transferId()/:refugeeId()",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId-refugeeId-room___de",
    path: ":transferId()/:refugeeId()/room",
    meta: roomteb3Kqv0IXMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId___de",
    path: ":transferId()",
    meta: index6ZlrG1xclsMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-cancelled___de",
    path: "cancelled",
    meta: cancelledIfuxV0VX7iMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-completed___de",
    path: "completed",
    meta: completedbliJH0RYECMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in___de",
    path: "",
    meta: indexTAwsmxnwzyMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: outkQjkX0TgKVMeta?.name,
    path: "out",
    component: () => import("/app/src/pages/context/[contextId]/transfers/out.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-out-transferId___de",
    path: ":transferId()",
    meta: indexlNmgSRCbvkMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-transferId-refugees___de",
    path: ":transferId()/refugees",
    meta: refugees9d1nCJlsL9Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/refugees.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-cancelled___de",
    path: "cancelled",
    meta: cancelledShJxGYiw28Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-completed___de",
    path: "completed",
    meta: completedfXUEHbmADKMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-draft___de",
    path: "draft",
    meta: draftFZJFBTkeaqMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/draft.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out___de",
    path: "",
    meta: indexlcs957ioY1Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: _91contextId_93Cp1xmLetI7Meta?.name,
    path: "/en/context/:contextId()",
    component: () => import("/app/src/pages/context/[contextId].vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId___en",
    path: "",
    component: () => import("/app/src/pages/context/[contextId]/index.vue").then(m => m.default || m)
  },
  {
    name: medicalO01VaFmbweMeta?.name,
    path: "medical",
    component: () => import("/app/src/pages/context/[contextId]/medical.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-medical-refugeeId-history___en",
    path: ":refugeeId()/history",
    meta: historyy2wjuG44KEMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId___en",
    path: ":refugeeId()",
    meta: indexOqxwCc91bmMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId-room___en",
    path: ":refugeeId()/room",
    meta: roomX3DpPlrR6UMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical___en",
    path: "",
    meta: indexCgOeJ37uImMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: refugeespqdM4e9bwEMeta?.name,
    path: "refugees",
    component: () => import("/app/src/pages/context/[contextId]/refugees.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-refugees-refugeeId-family___en",
    path: ":refugeeId()/family",
    meta: family59MvkaU3rAMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/family.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-history___en",
    path: ":refugeeId()/history",
    meta: history8XGfGJZsDDMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId___en",
    path: ":refugeeId()",
    meta: indexM3Lwq8moLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-messages___en",
    path: ":refugeeId()/messages",
    meta: messagesuNCDkhiHLIMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/messages.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-room___en",
    path: ":refugeeId()/room",
    meta: roomS0DFCh2EaYMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees___en",
    path: "",
    meta: index1RJof10h1bMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-registration___en",
    path: "registration",
    meta: registrationUjiwBlmesRMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: roomszQDu5nRonmMeta?.name,
    path: "rooms",
    component: () => import("/app/src/pages/context/[contextId]/rooms.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-rooms-roomId___en",
    path: ":roomId()",
    meta: indexnD2XFgPW3eMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-roomId-refugee___en",
    path: ":roomId()/refugee",
    meta: refugeeuI44YXvmf8Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/refugee.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-create___en",
    path: "create",
    meta: createEZtd3GpfOJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/create.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms___en",
    path: "",
    meta: indexfgydtwXQLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "context-contextId-transfers___en",
    path: "transfers",
    component: () => import("/app/src/pages/context/[contextId]/transfers.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-create___en",
    path: "create",
    meta: createPgNEgWZ7XfMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: in6aHw23rnH9Meta?.name,
    path: "in",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-in-transferId-refugeeId___en",
    path: ":transferId()/:refugeeId()",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId-refugeeId-room___en",
    path: ":transferId()/:refugeeId()/room",
    meta: roomteb3Kqv0IXMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId___en",
    path: ":transferId()",
    meta: index6ZlrG1xclsMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-cancelled___en",
    path: "cancelled",
    meta: cancelledIfuxV0VX7iMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-completed___en",
    path: "completed",
    meta: completedbliJH0RYECMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in___en",
    path: "",
    meta: indexTAwsmxnwzyMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: outkQjkX0TgKVMeta?.name,
    path: "out",
    component: () => import("/app/src/pages/context/[contextId]/transfers/out.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-out-transferId___en",
    path: ":transferId()",
    meta: indexlNmgSRCbvkMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-transferId-refugees___en",
    path: ":transferId()/refugees",
    meta: refugees9d1nCJlsL9Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/refugees.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-cancelled___en",
    path: "cancelled",
    meta: cancelledShJxGYiw28Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-completed___en",
    path: "completed",
    meta: completedfXUEHbmADKMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-draft___en",
    path: "draft",
    meta: draftFZJFBTkeaqMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/draft.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out___en",
    path: "",
    meta: indexlcs957ioY1Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: _91contextId_93Cp1xmLetI7Meta?.name,
    path: "/fr/context/:contextId()",
    component: () => import("/app/src/pages/context/[contextId].vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId___fr",
    path: "",
    component: () => import("/app/src/pages/context/[contextId]/index.vue").then(m => m.default || m)
  },
  {
    name: medicalO01VaFmbweMeta?.name,
    path: "medical",
    component: () => import("/app/src/pages/context/[contextId]/medical.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-medical-refugeeId-history___fr",
    path: ":refugeeId()/history",
    meta: historyy2wjuG44KEMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId___fr",
    path: ":refugeeId()",
    meta: indexOqxwCc91bmMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical-refugeeId-room___fr",
    path: ":refugeeId()/room",
    meta: roomX3DpPlrR6UMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-medical___fr",
    path: "",
    meta: indexCgOeJ37uImMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/medical/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: refugeespqdM4e9bwEMeta?.name,
    path: "refugees",
    component: () => import("/app/src/pages/context/[contextId]/refugees.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-refugees-refugeeId-family___fr",
    path: ":refugeeId()/family",
    meta: family59MvkaU3rAMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/family.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-history___fr",
    path: ":refugeeId()/history",
    meta: history8XGfGJZsDDMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/history.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId___fr",
    path: ":refugeeId()",
    meta: indexM3Lwq8moLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-messages___fr",
    path: ":refugeeId()/messages",
    meta: messagesuNCDkhiHLIMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/messages.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-refugeeId-room___fr",
    path: ":refugeeId()/room",
    meta: roomS0DFCh2EaYMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees___fr",
    path: "",
    meta: index1RJof10h1bMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-refugees-registration___fr",
    path: "registration",
    meta: registrationUjiwBlmesRMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/refugees/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: roomszQDu5nRonmMeta?.name,
    path: "rooms",
    component: () => import("/app/src/pages/context/[contextId]/rooms.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-rooms-roomId___fr",
    path: ":roomId()",
    meta: indexnD2XFgPW3eMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-roomId-refugee___fr",
    path: ":roomId()/refugee",
    meta: refugeeuI44YXvmf8Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/[roomId]/refugee.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms-create___fr",
    path: "create",
    meta: createEZtd3GpfOJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/create.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-rooms___fr",
    path: "",
    meta: indexfgydtwXQLJMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/rooms/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "context-contextId-transfers___fr",
    path: "transfers",
    component: () => import("/app/src/pages/context/[contextId]/transfers.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-create___fr",
    path: "create",
    meta: createPgNEgWZ7XfMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/create.vue").then(m => m.default || m)
  },
  {
    name: in6aHw23rnH9Meta?.name,
    path: "in",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-in-transferId-refugeeId___fr",
    path: ":transferId()/:refugeeId()",
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId-refugeeId-room___fr",
    path: ":transferId()/:refugeeId()/room",
    meta: roomteb3Kqv0IXMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/[refugeeId]/room.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-transferId___fr",
    path: ":transferId()",
    meta: index6ZlrG1xclsMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-cancelled___fr",
    path: "cancelled",
    meta: cancelledIfuxV0VX7iMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in-completed___fr",
    path: "completed",
    meta: completedbliJH0RYECMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-in___fr",
    path: "",
    meta: indexTAwsmxnwzyMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/in/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: outkQjkX0TgKVMeta?.name,
    path: "out",
    component: () => import("/app/src/pages/context/[contextId]/transfers/out.vue").then(m => m.default || m),
    children: [
  {
    name: "context-contextId-transfers-out-transferId___fr",
    path: ":transferId()",
    meta: indexlNmgSRCbvkMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/index.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-transferId-refugees___fr",
    path: ":transferId()/refugees",
    meta: refugees9d1nCJlsL9Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/[transferId]/refugees.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-cancelled___fr",
    path: "cancelled",
    meta: cancelledShJxGYiw28Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/cancelled.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-completed___fr",
    path: "completed",
    meta: completedfXUEHbmADKMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/completed.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out-draft___fr",
    path: "draft",
    meta: draftFZJFBTkeaqMeta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/draft.vue").then(m => m.default || m)
  },
  {
    name: "context-contextId-transfers-out___fr",
    path: "",
    meta: indexlcs957ioY1Meta || {},
    component: () => import("/app/src/pages/context/[contextId]/transfers/out/index.vue").then(m => m.default || m)
  }
]
  }
]
  }
]
  },
  {
    name: "departments___de",
    path: "/departments",
    meta: departmentsUVfcZw746CMeta || {},
    component: () => import("/app/src/pages/departments.vue").then(m => m.default || m)
  },
  {
    name: "departments___en",
    path: "/en/departments",
    meta: departmentsUVfcZw746CMeta || {},
    component: () => import("/app/src/pages/departments.vue").then(m => m.default || m)
  },
  {
    name: "departments___fr",
    path: "/fr/departments",
    meta: departmentsUVfcZw746CMeta || {},
    component: () => import("/app/src/pages/departments.vue").then(m => m.default || m)
  },
  {
    name: employeelAR8rsOsJXMeta?.name,
    path: "/employee",
    component: () => import("/app/src/pages/employee.vue").then(m => m.default || m),
    children: [
  {
    name: "employee-id___de",
    path: ":id()",
    meta: _91id_93q3k6oyjHI5Meta || {},
    component: () => import("/app/src/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: "employee___de",
    path: "",
    meta: indexI4HhVoZ9sLMeta || {},
    component: () => import("/app/src/pages/employee/index.vue").then(m => m.default || m)
  },
  {
    name: "employee-registration___de",
    path: "registration",
    meta: registration8aeFU8wNyLMeta || {},
    component: () => import("/app/src/pages/employee/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: employeelAR8rsOsJXMeta?.name,
    path: "/en/employee",
    component: () => import("/app/src/pages/employee.vue").then(m => m.default || m),
    children: [
  {
    name: "employee-id___en",
    path: ":id()",
    meta: _91id_93q3k6oyjHI5Meta || {},
    component: () => import("/app/src/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: "employee___en",
    path: "",
    meta: indexI4HhVoZ9sLMeta || {},
    component: () => import("/app/src/pages/employee/index.vue").then(m => m.default || m)
  },
  {
    name: "employee-registration___en",
    path: "registration",
    meta: registration8aeFU8wNyLMeta || {},
    component: () => import("/app/src/pages/employee/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: employeelAR8rsOsJXMeta?.name,
    path: "/fr/employee",
    component: () => import("/app/src/pages/employee.vue").then(m => m.default || m),
    children: [
  {
    name: "employee-id___fr",
    path: ":id()",
    meta: _91id_93q3k6oyjHI5Meta || {},
    component: () => import("/app/src/pages/employee/[id].vue").then(m => m.default || m)
  },
  {
    name: "employee___fr",
    path: "",
    meta: indexI4HhVoZ9sLMeta || {},
    component: () => import("/app/src/pages/employee/index.vue").then(m => m.default || m)
  },
  {
    name: "employee-registration___fr",
    path: "registration",
    meta: registration8aeFU8wNyLMeta || {},
    component: () => import("/app/src/pages/employee/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "forgot-password___de",
    path: "/forgot-password",
    meta: forgot_45passwordvHMplVNcGwMeta || {},
    component: () => import("/app/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    meta: forgot_45passwordvHMplVNcGwMeta || {},
    component: () => import("/app/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password___fr",
    path: "/fr/forgot-password",
    meta: forgot_45passwordvHMplVNcGwMeta || {},
    component: () => import("/app/src/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index___de",
    path: "/",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "index___fr",
    path: "/fr",
    meta: indexdPwi6hPdMfMeta || {},
    component: () => import("/app/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "invite-id___de",
    path: "/invite/:id()",
    meta: _91id_93CNRQ982hTLMeta || {},
    component: () => import("/app/src/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: "invite-id___en",
    path: "/en/invite/:id()",
    meta: _91id_93CNRQ982hTLMeta || {},
    component: () => import("/app/src/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: "invite-id___fr",
    path: "/fr/invite/:id()",
    meta: _91id_93CNRQ982hTLMeta || {},
    component: () => import("/app/src/pages/invite/[id].vue").then(m => m.default || m)
  },
  {
    name: locationstjOMXREOfBMeta?.name,
    path: "/locations",
    component: () => import("/app/src/pages/locations.vue").then(m => m.default || m),
    children: [
  {
    name: "locations-locationId___de",
    path: ":locationId()",
    meta: _91locationId_93jSvSR7CNxhMeta || {},
    component: () => import("/app/src/pages/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: "locations-create___de",
    path: "create",
    meta: createSe03WvH6AoMeta || {},
    component: () => import("/app/src/pages/locations/create.vue").then(m => m.default || m)
  },
  {
    name: "locations___de",
    path: "",
    meta: index3GLwBk4lUUMeta || {},
    component: () => import("/app/src/pages/locations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: locationstjOMXREOfBMeta?.name,
    path: "/en/locations",
    component: () => import("/app/src/pages/locations.vue").then(m => m.default || m),
    children: [
  {
    name: "locations-locationId___en",
    path: ":locationId()",
    meta: _91locationId_93jSvSR7CNxhMeta || {},
    component: () => import("/app/src/pages/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: "locations-create___en",
    path: "create",
    meta: createSe03WvH6AoMeta || {},
    component: () => import("/app/src/pages/locations/create.vue").then(m => m.default || m)
  },
  {
    name: "locations___en",
    path: "",
    meta: index3GLwBk4lUUMeta || {},
    component: () => import("/app/src/pages/locations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: locationstjOMXREOfBMeta?.name,
    path: "/fr/locations",
    component: () => import("/app/src/pages/locations.vue").then(m => m.default || m),
    children: [
  {
    name: "locations-locationId___fr",
    path: ":locationId()",
    meta: _91locationId_93jSvSR7CNxhMeta || {},
    component: () => import("/app/src/pages/locations/[locationId].vue").then(m => m.default || m)
  },
  {
    name: "locations-create___fr",
    path: "create",
    meta: createSe03WvH6AoMeta || {},
    component: () => import("/app/src/pages/locations/create.vue").then(m => m.default || m)
  },
  {
    name: "locations___fr",
    path: "",
    meta: index3GLwBk4lUUMeta || {},
    component: () => import("/app/src/pages/locations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "login___de",
    path: "/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "login___fr",
    path: "/fr/login",
    meta: login3hUYZUGxmUMeta || {},
    component: () => import("/app/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "refugees___de",
    path: "/refugees",
    meta: refugeesmy0KMGzBodMeta || {},
    component: () => import("/app/src/pages/refugees.vue").then(m => m.default || m)
  },
  {
    name: "refugees___en",
    path: "/en/refugees",
    meta: refugeesmy0KMGzBodMeta || {},
    component: () => import("/app/src/pages/refugees.vue").then(m => m.default || m)
  },
  {
    name: "refugees___fr",
    path: "/fr/refugees",
    meta: refugeesmy0KMGzBodMeta || {},
    component: () => import("/app/src/pages/refugees.vue").then(m => m.default || m)
  },
  {
    name: "reset-password-id___de",
    path: "/reset-password/:id()",
    meta: _91id_93aEoJNaKwmLMeta || {},
    component: () => import("/app/src/pages/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-id___en",
    path: "/en/reset-password/:id()",
    meta: _91id_93aEoJNaKwmLMeta || {},
    component: () => import("/app/src/pages/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: "reset-password-id___fr",
    path: "/fr/reset-password/:id()",
    meta: _91id_93aEoJNaKwmLMeta || {},
    component: () => import("/app/src/pages/reset-password/[id].vue").then(m => m.default || m)
  },
  {
    name: "rooms___de",
    path: "/rooms",
    meta: roomsoMnB2LmSmLMeta || {},
    component: () => import("/app/src/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___en",
    path: "/en/rooms",
    meta: roomsoMnB2LmSmLMeta || {},
    component: () => import("/app/src/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: "rooms___fr",
    path: "/fr/rooms",
    meta: roomsoMnB2LmSmLMeta || {},
    component: () => import("/app/src/pages/rooms.vue").then(m => m.default || m)
  },
  {
    name: scannersIFcHra0jmUMeta?.name,
    path: "/scanners",
    component: () => import("/app/src/pages/scanners.vue").then(m => m.default || m),
    children: [
  {
    name: "scanners-scannerId___de",
    path: ":scannerId()",
    meta: _91scannerId_93V9C3myOlntMeta || {},
    component: () => import("/app/src/pages/scanners/[scannerId].vue").then(m => m.default || m)
  },
  {
    name: "scanners___de",
    path: "",
    meta: indexh29AHfaGptMeta || {},
    component: () => import("/app/src/pages/scanners/index.vue").then(m => m.default || m)
  },
  {
    name: "scanners-registration___de",
    path: "registration",
    meta: registrationeJV6toxVOhMeta || {},
    component: () => import("/app/src/pages/scanners/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: scannersIFcHra0jmUMeta?.name,
    path: "/en/scanners",
    component: () => import("/app/src/pages/scanners.vue").then(m => m.default || m),
    children: [
  {
    name: "scanners-scannerId___en",
    path: ":scannerId()",
    meta: _91scannerId_93V9C3myOlntMeta || {},
    component: () => import("/app/src/pages/scanners/[scannerId].vue").then(m => m.default || m)
  },
  {
    name: "scanners___en",
    path: "",
    meta: indexh29AHfaGptMeta || {},
    component: () => import("/app/src/pages/scanners/index.vue").then(m => m.default || m)
  },
  {
    name: "scanners-registration___en",
    path: "registration",
    meta: registrationeJV6toxVOhMeta || {},
    component: () => import("/app/src/pages/scanners/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: scannersIFcHra0jmUMeta?.name,
    path: "/fr/scanners",
    component: () => import("/app/src/pages/scanners.vue").then(m => m.default || m),
    children: [
  {
    name: "scanners-scannerId___fr",
    path: ":scannerId()",
    meta: _91scannerId_93V9C3myOlntMeta || {},
    component: () => import("/app/src/pages/scanners/[scannerId].vue").then(m => m.default || m)
  },
  {
    name: "scanners___fr",
    path: "",
    meta: indexh29AHfaGptMeta || {},
    component: () => import("/app/src/pages/scanners/index.vue").then(m => m.default || m)
  },
  {
    name: "scanners-registration___fr",
    path: "registration",
    meta: registrationeJV6toxVOhMeta || {},
    component: () => import("/app/src/pages/scanners/registration.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "security___de",
    path: "/security",
    component: () => import("/app/src/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___en",
    path: "/en/security",
    component: () => import("/app/src/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "security___fr",
    path: "/fr/security",
    component: () => import("/app/src/pages/security.vue").then(m => m.default || m)
  },
  {
    name: "support___de",
    path: "/support",
    component: () => import("/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___en",
    path: "/en/support",
    component: () => import("/app/src/pages/support.vue").then(m => m.default || m)
  },
  {
    name: "support___fr",
    path: "/fr/support",
    component: () => import("/app/src/pages/support.vue").then(m => m.default || m)
  }
]