type ToastUI = 'success' | 'danger' | 'warning' | 'info'

interface ToastMessage {
  id: string
  ui: ToastUI
  message: string
  timerId: number | null
}

interface Toast {
  messages: ToastMessage[]
  success(message: string, autoclose?: boolean): void
  danger(message: string, autoclose?: boolean): void
  warning(message: string, autoclose?: boolean): void
  info(message: string, autoclose?: boolean): void
}

const messages = reactive<ToastMessage[]>([])

export const useToast = (): Toast => {
  const show = (
    ui: ToastUI,
    message: string,
    autoclose: boolean = true,
  ): (() => void) => {
    const data: ToastMessage = {
      id: uid(),
      ui,
      message,
      timerId: autoclose
        ? window.setTimeout(() => {
            close()
          }, 4000)
        : null,
    }

    const close = (): void => {
      const index = messages.findIndex((item) => item.id === data.id)
      if (index >= 0) messages.splice(index, 1)
    }

    messages.push(data)

    return close
  }

  return {
    messages,
    success: (message, autoclose) => show('success', message, autoclose),
    danger: (message, autoclose) => show('danger', message, autoclose),
    warning: (message, autoclose) => show('warning', message, autoclose),
    info: (message, autoclose) => show('info', message, autoclose),
  }
}
