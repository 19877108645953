import type { Access } from '~/plugins/access'

const hasAccessNamespaces = (
  namespaces: unknown,
): namespaces is Record<string, string[]> => isTrueObject(namespaces)

export default defineNuxtRouteMiddleware((to) => {
  if (!hasAccessNamespaces(to.meta.access)) return

  const { $access } = useNuxtApp()

  const hasAccess = Object.entries(to.meta.access).some(([key, value]) => {
    if (!Array.isArray(value)) return false

    const access = $access.value[key as keyof Access]
    const canRead = value.includes('read') && access?.read
    const canWrite = value.includes('write') && access?.write

    return canRead || canWrite
  })

  if (!hasAccess) {
    return showError({ statusCode: 403 })
  }
})
