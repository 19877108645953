import { useCookieStore } from './cookie'
import { useStore } from './store'
import type { IdentityClientService, IdentityServiceUserDetails } from './types'

export const useIdentityService = (): IdentityClientService => {
  const runtimeConfig = useRuntimeConfig()

  const { $link } = useNuxtApp()

  const store = useStore()
  const cookie = useCookieStore()

  const service: IdentityClientService = {
    data: reactive({
      accessToken: computed<string>(
        () => cookie.data.accessToken || store.accessToken,
      ),
      refreshToken: computed<string>(
        () => cookie.data.refreshToken || store.refreshToken,
      ),
      userDetails: computed<IdentityServiceUserDetails | null>({
        get: () => store.userDetails,
        set(data) {
          store.userDetails = data
        },
      }),
    }),

    isExpired() {
      return !cookie.data.accessToken || !cookie.data.refreshToken
    },

    async clear() {
      store.accessToken = ''
      store.refreshToken = ''
      store.userDetails = null
      cookie.clear()

      await nextTick()
    },

    async set(props) {
      store.accessToken = props.access_token
      store.refreshToken = props.refresh_token
      cookie.set(props)

      await nextTick()
    },

    async login(data) {
      const response = await fetch(`${runtimeConfig.public.identity}/token`, {
        method: 'POST',
        body: new URLSearchParams(
          Object.entries({
            ...data,
            grant_type: 'password',
            client_id: runtimeConfig.public.clientId,
          }),
        ),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.ok) {
        const data = await response.json()
        await service.set(data)
      } else {
        throw response
      }
    },

    async logout() {
      await fetch(`${runtimeConfig.public.identity}/revoke`, {
        method: 'POST',
        body: new URLSearchParams({
          client_id: runtimeConfig.public.clientId,
          token: service.data.accessToken,
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      await service.clear()

      location.href = $link.login()
    },

    async requestNewTokens() {
      const response = await fetch(`${runtimeConfig.public.identity}/token`, {
        method: 'POST',
        body: new URLSearchParams({
          grant_type: 'refresh_token',
          client_id: runtimeConfig.public.clientId,
          refresh_token: service.data.refreshToken,
        }),
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.ok) {
        const data = await response.json()
        return data
      } else {
        await service.clear()
        throw response
      }
    },

    async syncCookie(props) {
      if (props) {
        cookie.set(props)

        if (process.browser) {
          store.accessToken = props.access_token
          store.refreshToken = props.refresh_token
        }
      } else {
        cookie.clear()
      }

      await nextTick()
    },

    async fetchUserDetails() {
      const response = await fetch(
        `${runtimeConfig.public.identity}/userinfo`,
        {
          headers: {
            Authorization: `Bearer ${service.data.accessToken}`,
          },
        },
      )

      if (response.ok) {
        const data = (await response.json()) as IdentityServiceUserDetails
        service.data.userDetails = data
      } else {
        throw response
      }
    },
  }

  return service
}
